





















import Vue from "vue";
import AuthContent from "@/components/templates/AuthContent.vue";

export default Vue.extend({
  name: "template_main",

  components: {
    AuthContent
  }
});
