var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-content",
    {
      scopedSlots: _vm._u([
        {
          key: "bottomContent",
          fn: function() {
            return [_c("div", { staticClass: "pa-4" }, [_vm._v("bottom")])]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "pa-4" },
        [
          _c("h2", [_vm._v("some title")]),
          _vm._v(" content "),
          _c("br"),
          _vm._v("content"),
          _c("br"),
          _c("router-link", { attrs: { to: "/design/maincontent" } }, [
            _vm._v("main view")
          ]),
          _c("br"),
          _c("router-link", { attrs: { to: "/design/authcontent" } }, [
            _vm._v("auth view")
          ]),
          _c("br"),
          _c("router-link", { attrs: { to: "/design/conferencecontent" } }, [
            _vm._v("conference view ")
          ]),
          _c("br"),
          _c("router-link", { attrs: { to: "/design" } }, [
            _vm._v("back to design system")
          ]),
          _c("br")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }